import { defineTargetValuesFromDataset } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  connect() {
    this.element.setAttribute('data-fuse-modal-triggers-ready', '');
  }

  disconnect() {
    this.element.removeAttribute('data-fuse-modal-triggers-ready');
  }

  openModal(event) {
    if (!event) return;

    const target = event.currentTarget;
    if (target.href && target.href !== '#' && (event.altKey || event.ctrlKey || event.metaKey)) return;

    event.preventDefault();

    const { name, ...options } = this._triggerModalDataProxy(target);

    if (['a', 'button'].includes(target.tagName.toLowerCase()) && !name && !options.url) return;

    this.dispatchOnWindow('open', {
      prefix: 'modal',
      detail: {
        name,
        trigger: target,
        options,
      },
    });
  }

  closeModal(event = null) {
    if (!event) return;

    event.preventDefault();

    const target = event.currentTarget;
    const { name } = this._triggerModalDataProxy(target);

    this.dispatchOnWindow('close', {
      prefix: 'modal',
      detail: {
        name,
        trigger: target,
      },
    });
  }

  _triggerModalDataProxy(trigger) {
    const modalData = this._createTriggerModalDataProxy(trigger);

    if ((!modalData.url || modalData.url === '#') && trigger.href) {
      modalData.url = trigger.href;
    }

    return modalData;
  }

  _createTriggerModalDataProxy(item) {
    return defineTargetValuesFromDataset(item, 'modal', {
      name: {
        type: String,
        default: 'modalV2',
      },
      withHistory: {
        type: Boolean,
        default: false,
      },
      url: {
        type: String,
        default: undefined,
      },
      urlParams: {
        type: String,
        default: undefined,
      },
      requestMethod: {
        type: String,
        default: 'GET',
      },
      requestContentType: {
        type: String,
        default: undefined,
      },
      requestCsrfToken: {
        type: String,
        default: undefined,
      },
      requestBody: {
        type: String,
        default: undefined,
      },
      autofocus: {
        type: Boolean,
        default: true,
      },
    });
  }
}
