import { pushPageViewToDataLayer } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static values = {
    type: String,
    params: Object,
  };

  connect() {
    pushPageViewToDataLayer({
      event: this.pageViewEvent,
      type: this.typeValue || 'other',
      title: this.pageTitle,
      path: this.path,
      location: this.url,
      ...this.paramsValue,
    });
  }

  get pageViewEvent() {
    if (this.typeValue === 'embed') {
      return 'embed_view';
    }

    return 'page_view';
  }

  get pageTitle() {
    return window.document.title;
  }

  get path() {
    return window.location.href.replace(window.location.origin, '');
  }

  get url() {
    return window.location.href;
  }
}
