import { defer, generateRandomId, remove } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static targets = ['template', 'empty', 'list', 'item', 'destroyInput'];

  static values = {
    confirmationMessage: { type: String, default: null },
    position: { type: String, default: 'beforeend' },
  };

  add({ detail: { position = this.positionValue } = {} } = {}) {
    const html = this.createTemplate();

    this.listTarget.insertAdjacentHTML(position, html);
    this.toggleEmptyState(false);
    this.notifyChange('added');
  }

  append() {
    this.add();
  }

  prepend() {
    this.add({ detail: { position: 'afterbegin' } });
  }

  delete({ target }) {
    if (!this.confirmDelete(target)) return;

    const item = this.findParentItem(target);

    if (!item) return;

    this.handleItemDeletion(item);
    this.notifyChange('removed');
  }

  createTemplate() {
    return this.templateTarget.innerHTML.replaceAll('REPLACE_WITH_CHILD_INDEX', this.generateUniqueIndex());
  }

  generateUniqueIndex() {
    return generateRandomId(24, { numeric: true });
  }

  findParentItem(element) {
    return this.itemTargets.find((item) => item.contains(element));
  }

  handleItemDeletion(item) {
    if (this.isNewRecord(item)) {
      remove(item);
    } else {
      this.markAsDestroyed(item);
    }

    this.toggleEmptyState(this.visibleItems === 0);
  }

  isNewRecord(item) {
    return item.dataset.nestedFormNewRecord === 'true';
  }

  markAsDestroyed(item) {
    const destroyInput = this.destroyInputTargets.find((input) => item.contains(input));

    if (destroyInput) {
      destroyInput.value = '1';
      item.hidden = true;
    }
  }

  confirmDelete(target) {
    const message = target.dataset.confirmDelete || this.confirmationMessageValue;

    return !message || window.confirm(message);
  }

  toggleEmptyState(show) {
    if (this.hasEmptyTarget) {
      this.emptyTarget.hidden = !show;
    }
  }

  notifyChange(action) {
    defer(() => this.dispatch(action, { target: this.listTarget }));
  }

  get visibleItems() {
    return this.hasItemTarget ? this.itemTargets.reduce((sum, target) => (target.hidden ? sum : sum + 1), 0) : 0;
  }
}
